/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneTrendingMenu as ResolvedStandaloneTrendingMenu } from 'base/components/standalone/TrendingMenu';

export const TrendingMenu = ResolvedStandaloneTrendingMenu;

export type TrendingMenuProps = PropsFromComponent<typeof TrendingMenu>;

export const TrendingMenuWith = (extras: DynamicStandaloneExtras): typeof TrendingMenu => {
    return function TrendingMenu(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'TrendingMenu');
        return <ResolvedStandaloneTrendingMenu {...mergeProps({ options: { theme } }, props)} />;
    }
}