import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import { default as BaseTrendingMenuTheme } from 'base/components/TrendingMenu/theme';

const Tag = tw.theme({
  extend: TagTheme,
  base: '',
});

const TrendingMenu = tw.theme({
  extend: BaseTrendingMenuTheme,
  slots: {
    base: `px-4 sm:my-4`,
    headline: `text-body-sm md:text-body-md`,
  },
});

export default Object.assign(TrendingMenu, { Tag });
